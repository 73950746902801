import Navbar from "./components/Navbar";
import MapBox from "./components/MapBox";
import CountryInformation from "./components/CountryInformation";
import globalHeartbeatLogo from "./images/favicon.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      width: "auto",
      position: "fixed",
      bottom: 20,
      left: 20,
      transition : '300ms',
      [theme.breakpoints.up("md")]: {
        height: 50,
      },
      [theme.breakpoints.down("md")]: {
        height: 35,
      },
      '&:hover':{
          transform : 'scale(1.5)'
      }
    },
  };
});

function App() {
    const classes = useStyles()
  return (
    <div>
      <MapBox />
      <Navbar />
      <CountryInformation />
      <img src={globalHeartbeatLogo} className={classes.logo} alt="Global Heartbeat logo"/>
    </div>
  );
}

export default App;
