import {
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Stop } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";



function Legends() {
  const legends = useSelector((state) => state.legends);

  return (
    <List>
		<ListItem>
			<Typography variant='h6'>
				Legends
			</Typography>
		</ListItem>
      {legends.map((legend, index) => {
        return (
          <ListItem disableGutters style={{padding:0, marginY:0}} key={index}>
            <Typography key={index}>
              <Stop style={{ fill: legend.color }} /> {legend.text}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
}

export default Legends;
