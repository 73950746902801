import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import {
  List,
  ListItem,
  Divider,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Card,
  CardContent,
  Slide,
} from "@material-ui/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectCountry } from "../actions/countryActions";
import toggleSidebar from "../actions/sidebarActions";
import loadData from "../actions/dataActions";
import { fetchCountries } from "../actions/countriesActions";
import toggleCountryInfo from "../actions/countryInfoActions";
import CategorySelectBox from "./CategorySelectBox";
import TimelineRangeSlider from "./TimelineRangeSlider";
import SentinelHubSelectBox from "./SentinelHubSelectBox";

function Navbar() {
  const countries = useSelector((state) => state.countries);
  const sidebar = useSelector((state) => state.sidebar);
  const country = useSelector((state) => state.country);

  const dispatch = useDispatch();
  const model = "Global";
  const indicators = ["HA", "VU", "CC", "INFORM"];
  const [informReleases, setInformReleases] = useState([]);
  const [_indicators, _setIndicators] = useState([]);

  useEffect(() => {
    dispatch(fetchCountries());
    fetchIndicators();
    fetchInformList();
    // eslint-disable-next-line
  }, []);

  const fetchIndicators = async () => {
    let response = await axios.get(
      `https://drmkc.jrc.ec.europa.eu/Inform-Index/API/InformAPI/Indicators/Index`
    );

    let filtered = await response.data.filter((indicator) => {
      return (
        indicator.IndicatorId.startsWith("HA") ||
        indicator.IndicatorId.startsWith("VU") ||
        indicator.IndicatorId.startsWith("CC")
      );
    });
    await _setIndicators(filtered);
  };

  const fetchInformList = async () => {
    let response = await axios.get(
      `https://drmkc.jrc.ec.europa.eu/Inform-Index/API/InformAPI/workflows/WorkflowGroups/?model=${model}`
    );
    let filteredInforms = await response.data.filter((inform) => {
      return inform !== "INFORM_LAC" && inform !== "INFORM_LBN";
    });

    let workflows = Promise.all(
      await filteredInforms.map(async (inform) => {
        let response = await axios.get(
          `https://drmkc.jrc.ec.europa.eu/Inform-Index/API/InformAPI/workflows/GetByWorkflowGroup/${inform}`
        );

        let newWorkflow = await response.data.pop();
        return await { inform: inform, workflow: newWorkflow };
      })
    );

    await setInformReleases(await workflows);
  };

  const getResult = async (country) => {
    let indicatorList = indicators.join(",") + ','  + _indicators.map((indicator) => indicator.IndicatorId).join(",")

    let _data = await Promise.all(
      await informReleases.map(async (informRelease) => {
        let response = await axios.get(
          `https://drmkc.jrc.ec.europa.eu/Inform-Index/API/InformAPI/countries/Scores/?WorkflowId=${informRelease.workflow.WorkflowId}&Iso3=${country}&IndicatorId=${indicatorList}}`
        );
        return await {
          informYear: informRelease.inform.replace("INFORM", ""),
          data: response.data,
        };
      })
    );

    await dispatch(loadData(_data));
    await dispatch(toggleCountryInfo(true));
  };

  return (
    <div>
      <Fab
        style={{ position: "fixed", left: 20, top: 20 }}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
        size="small"
      >
        <MenuIcon />
      </Fab>

      <Slide in={sidebar} timeout={{appear:300, enter:300, exit:300}} direction='right'>
        <Card
          style={{
            position: "absolute",
            top: 20,
            left: 20,
            borderRadius: 10,
            minWidth:300
          }}
        >
          <CardContent>
            <Grid container spacing={1} justify="space-between">
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => {
                    dispatch(toggleSidebar());
                  }}
                  fullWidth={false}
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>

            <Divider />
            <List>
              <ListItem button>
                <FormControl fullWidth>
                  <InputLabel id="country">Country</InputLabel>
                  <Select
                    labelId="country"
                    id="country"
                    onChange={(e) => {
                      let selectedCountry = e.target.value;
                      dispatch(selectCountry(selectedCountry));
                      getResult(selectedCountry);
                    }}
                    value={country.iso_a3 || ""}
                  >
                    {countries.map((country, index) => {
                      return (
                        <MenuItem value={country.Iso3} key={index}>
                          {country.CountryName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ListItem>

              <ListItem button>
                <CategorySelectBox />
              </ListItem>
              <ListItem button>
                <TimelineRangeSlider />
              </ListItem>
              <ListItem>
                <SentinelHubSelectBox/>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Slide>
    </div>
  );
}

export default Navbar;
