import { Button, FormControl, InputLabel, MenuItem, Select, Grid } from "@material-ui/core";
import {Visibility, VisibilityOff} from '@material-ui/icons/';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "../actions/categoryActions";
import { setActiveLayers } from "../actions/layersActions";

function CategorySelectBox() {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState({});
    const [sedacLayerChecked, setSedacLayerChecked] = useState(true);
    const categories = useSelector((state) => state.categories);

    const sedacToggleCheck = (e) => {
        let status = !sedacLayerChecked;
        setSedacLayerChecked(status);
        dispatch(setActiveLayers({ sedacLayer: status }));
    };

    return (
        <>
            <Grid container alignContent="center">
                <Grid item xs={2}>
                    <Button
                        startIcon={
                            sedacLayerChecked ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )
                        }
                        fullWidth
                        style={{ width: "auto" }}
                        onClick={sedacToggleCheck}
                    ></Button>
                </Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <InputLabel id="category">SEDAC Data</InputLabel>
                        <Select
                            labelId="category"
                            id="category"
                            value={selectedCategory ? selectedCategory : ''}
                            onChange={(e) => {
                                let selectedCategory = e.target.value;
                                setSelectedCategory(selectedCategory);
                                let category = categories
                                    .filter((category) => {
                                        return (
                                            category.key === selectedCategory
                                        );
                                    })
                                    .pop();
                                dispatch(setCategory(category));
                            }}
                        >
                            {categories.map((category, index) => {
                                return (
                                    <MenuItem
                                        value={category.key ? category.key : ""}
                                        key={index}
                                    >
                                        {category.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}

export default CategorySelectBox;
