import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

function DataTable() {
  const data = useSelector(state => state.data)
  const classes = useStyles();

  const getIndicatoreScore = (data, IndicatorId) => {
    let res = data.find(indicator => indicator.IndicatorId === IndicatorId)
    return res ? res.IndicatorScore.toFixed(2) : 'N/A'
  }

  useEffect(() => {
    console.log('Table mounted')
    console.log(data)

  }, [data])

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table aria-label="simple table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>Inform Release</TableCell>
                <TableCell align="right">HA</TableCell>
                <TableCell align="right">VU</TableCell>
                <TableCell align="right">CC</TableCell>
                <TableCell align="right">Inform</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.informYear}>
                  <TableCell component="th" scope="row">
                    {row.informYear}
                  </TableCell>
                  <TableCell align="right">
                    {row.data.length
                      ? getIndicatoreScore(row.data, 'HA')
                      : "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {row.data[3]
                      ? getIndicatoreScore(row.data, 'VU')
                      : "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {row.data[0]
                      ? getIndicatoreScore(row.data, 'CC')
                      : "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {row.data[2]
                      ? getIndicatoreScore(row.data, 'INFORM')
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

DataTable.propTypes = {
  data: PropTypes.array,
};

DataTable.defaultProps = {
  data: [],
};

export default DataTable;
