const countryReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "SET_COUNTRY":
      return payload;
      // eslint-disable-next-line
      break;
    default:
      return state;
      // eslint-disable-next-line
      break;
  }
};

export default countryReducer;
