import { isBoolean } from "lodash";

const countryInfoReducer = (state = false, { type, payload }) => {
  switch (type) {
    case "TOGGLE_COUNTRY_INFO":
      return isBoolean(payload) ? payload :  !state;

    default:
      return state;
  }
};

export default countryInfoReducer;