const sentinelLayerReducer = (state = {}, { type, payload }) => {
    switch (type) {
      case "SET_SENTINEL_LAYER":
        return payload;
  
      default:
        return state;
    }
  };
  
  export default sentinelLayerReducer;
  