import axios from "axios";

export const selectCountry = (country) => {
  return async (dispatch, getState) => {
    let geoJsonData = await axios("../data/custom.geo-hd.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    let countryData = await geoJsonData.data.features.filter( feature=>{
        return feature.properties.iso_a3 === country
    }).map(feature => {
        return {iso_a3: feature.properties.iso_a3 , ...feature }
    })
    if(countryData.length)
        await dispatch(setCountry(countryData.pop()))
    else
        await dispatch(setCountry({}))
  };
};


export const setCountry = (countryData) => {
  return {
    type: "SET_COUNTRY",
    payload: countryData,
  };
};
