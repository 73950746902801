import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import {
    Typography,
    Button,
    Card,
    CardContent,
    Grid,
    CardMedia,
    Collapse,
    makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { ArrowRight, Info } from "@material-ui/icons";
import toggleCountryInfo from "../actions/countryInfoActions";
import informRiskBanner from "../images/RISKBanner.png";
import nasaLogo from "../images/nasa.png";
import Legends from "./Legends";
import axios from "axios";
import DataTabNavigation from "./DataTabNavigation";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: "absolute",
            top: 20,
            right: 60,
            borderRadius: 10,
            [theme.breakpoints.up("md")]: {
                width: 700,
            },
            [theme.breakpoints.down("md")]: {
                width: "98vw",
                top: 5,
                right: 5,
            },
        },
        countryText: {
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
            [theme.breakpoints.down("md")]: {
                display: "block",
            },
        },
    };
});

function CountryInformation() {
    const classes = useStyles();
    const country = useSelector((state) => state.country);
    const data = useSelector((state) => state.data);
    const countryInfo = useSelector((state) => state.countryInfo);
    const category = useSelector((state) => state.category);
    // eslint-disable-next-line
    const [showBtnText, setShowBtnText] = useState(false);
    const [indicators, setIndicators] = useState([]);
    const [showIndicators, setShowIndicators] = useState(false);
    const dispatch = useDispatch();

    const toggleBtnTextShow = (status) => {
        setShowBtnText(status);
    };

    const countryInfoBtn =
        country && data.length ? (
            <Fab
                style={{
                    position: "fixed",
                    top: 20,
                    right: 20,
                    transition: "300ms",
                }}
                onClick={() => {
                    dispatch(toggleCountryInfo(true));
                }}
                variant="extended"
                size="small"
                onMouseEnter={(e) => {
                    toggleBtnTextShow(true);
                }}
                onMouseLeave={(e) => {
                    toggleBtnTextShow(false);
                }}
            >
                {/* <span style={{ ...isTextShowed, transition: "300ms" }}>
                    Country Information
                </span> */}
                <Info />
            </Fab>
        ) : (
            ""
        );

    const toggleIndicators = () => {
        setShowIndicators(!showIndicators);
    };

    const fetchIndicators = async () => {
        let response = await axios.get(
            `https://drmkc.jrc.ec.europa.eu/Inform-Index/API/InformAPI/Indicators/Index`
        );

        let filtered = await response.data.filter((indicator) => {
            return (
                indicator.IndicatorId.startsWith("HA") ||
                indicator.IndicatorId.startsWith("VU") ||
                indicator.IndicatorId.startsWith("CC")
            );
        });
        await setIndicators(filtered);
    };

    useEffect(() => {
        fetchIndicators();
    }, []);

    return (
        <>
            {countryInfoBtn}

            <Collapse
                in={countryInfo}
                timeout={{ appear: 300, enter: 300, exit: 300 }}
                direction="left"
            >
                <Card classes={classes}>
                    <CardContent
                        style={{
                            height: "90vh",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            position: "relative",
                        }}
                    >
                        <Grid container justify="flex-end">
                            <Grid
                                item 
                                key='country-name'
                                style={{
                                    justifySelf: "flex-start",
                                    flexGrow: 1,
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    className={classes.countryText}
                                >
                                    {country.properties
                                        ? country.properties.subunit
                                        : ""}
                                </Typography>
                            </Grid>
                            <Grid item 
                            key='country-collaps-btn'>
                                <Button
                                    color="secondary"
                                    title="Hide country information"
                                    onClick={() => {
                                        dispatch(toggleCountryInfo(false));
                                    }}
                                >
                                    <ArrowRight fontSize="large" />
                                </Button>
                            </Grid>
                        </Grid>
                        <CardMedia
                            image={informRiskBanner}
                            style={{
                                height: 100,
                                width: "auto",
                                borderRadius: 10,
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        ></CardMedia>

                        <DataTabNavigation mt={20} />
                        <Grid container direction="column">
                            <Grid item>
                                <Typography
                                    variant="h5"
                                    style={{ cursor: "pointer" }}
                                    onClick={toggleIndicators}
                                >
                                    Indicators{" "}
                                    <span
                                        style={{ color: "#aaa", fontSize: 12 }}
                                    >
                                        {!showIndicators
                                            ? "(Click to show indicators)"
                                            : "(Click to close indicators)"}
                                    </span>
                                </Typography>
                            </Grid>
                            <Collapse in={showIndicators}>
                                {indicators.map((indicator, index) => {
                                    return (
                                        <Grid item key={index}>
                                            <Typography>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {indicator.IndicatorId}
                                                </span>{" "}
                                                {indicator.IndicatorDescription}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Collapse>
                        </Grid>

                        <Grid container>
                            <Grid item sm={2} md={2}>
                                
                                <img
                                    src={nasaLogo}
                                    style={{ height: 75, width: "auto" }}
                                    alt="NASA logo"
                                />
                            </Grid>
                            <Grid
                                item
                                sm={10}
                                md={10}
                                style={{ paddingLeft: 20, paddingTop: 20 }}
                            >
                                <Typography variant="h5">
                                    Socioeconomic Data and Applications Center
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={8} style={{ padding: 8 }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="h5">
                                            {category.name ? category.name : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {category.purpose ? category.purpose : ""}
                                </Grid>
                            </Grid>
                            <Grid item md={4}>
                                <Legends />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Collapse>
        </>
    );
}

export default CountryInformation;
