import countryReducer from "./countryReducer";
import { combineReducers } from "redux";
import sideBarReducer from "./sidebarReducer";
import dataReducer from "./dataReducer";
import countriesReducer from "./countriesReducer";
import countryInfoReducer from "./countryInfoReducer";
import sedacLayerReducer from "./sedacLayerReducer";
import legendReducer from "./legendReducer";
import categoriesReducer from "./categoriesReducer";
import categoryReducer from "./categoryReducer";
import sentinelLayerReducer from "./sentinelLayerReducer";
import layersReducer from "./layersReducer";
import sentinelDateReducer from "./sentinelDateReducer";

const reducers = combineReducers({
  country: countryReducer,
  countries: countriesReducer,
  sidebar: sideBarReducer,
  countryInfo: countryInfoReducer,
  data: dataReducer,
  sedac: sedacLayerReducer,
  legends: legendReducer,
  category: categoryReducer,
  categories: categoriesReducer,
  sentinelLayer : sentinelLayerReducer,
  activeLayer: layersReducer,
  sentinelDateRange: sentinelDateReducer
});

export default reducers;
