import { minBy, maxBy, flattenDeep } from "lodash";

export const filterData = (data) => {
  // eslint-disable-next-line array-callback-return


  let chartData = [];
  let datas = [];

  if (data.length) {

    chartData = {
      labels: [...data].map((inform) => {
        return inform.informYear;
      }),
    };

    let indicators = new Set();
    data.forEach(inform => {
        inform.data.forEach(data => {
            indicators.add(data.IndicatorId);
        });
    });
    indicators = [...indicators];


    // eslint-disable-next-line
    let a = [...data].map(inform => {
      datas.push(inform.data)
    })

    let datasets = indicators.map(indicator => {
      return {
        label: indicator, borderColor: randomColor(), data: datas.map(data => {
          return data.filter(_data => _data.IndicatorId === indicator).pop()
        }).map(data => {
          return data ? data.IndicatorScore.toFixed(2) : null
        })
      }
    })
    chartData = { ...chartData, datasets: datasets }
  }




  return chartData;
};

const randomColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}


export const getCenterCoordinates = (coordinates) => {
  let flattenCoordinates = flattenDeep(coordinates);

  let longs = [...flattenCoordinates].filter((coord, index) => index % 2 === 0);
  let lats = [...flattenCoordinates].filter((coord, index) => index % 2 === 1);
  return {
    long: (maxBy(longs) + minBy(longs)) / 2,
    lat: (maxBy(lats) + minBy(lats)) / 2,
  };
};

export const getBounds = (coordinates) => {
  let flattenCoordinates = flattenDeep(coordinates);

  let longs = [...flattenCoordinates].filter((coord, index) => index % 2 === 0);
  let lats = [...flattenCoordinates].filter((coord, index) => index % 2 === 1);
  return [
    [minBy(longs), minBy(lats)],
    [maxBy(longs), maxBy(lats)],
  ];
};


export const generatePolygon = (coordinates) => {
  console.log(coordinates);
  let wkt_parsed = `POLYGON(${coordinates.map(coordinate => {
    return '(' + coordinate.map(coordinate => `${coordinate[1]} ${coordinate[0]}`) + ')'
  }).join(',')})`
  console.log(wkt_parsed);
  return wkt_parsed
}
