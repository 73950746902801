const sedacLayerReducer = (state = "", { type, payload }) => {
  switch (type) {
    case "SET_SEDAC_LAYER":
      return payload;
      // eslint-disable-next-line
      break
    default:
      return state;
      // eslint-disable-next-line
      break
  }
};

export default sedacLayerReducer;
