const initialState = {
    startDate: null,
    endDate: null
}

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case 'SET_SENTINEL_DATE':
            return { ...state, ...payload }

        default:
            return state
    }
}
