import {
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Grid,
    Button,
    TextField,
    Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons/";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveLayers } from "../actions/layersActions";
import setSentinelLayer from "../actions/sentinelLayerAction";
import { setSentinelDate } from "../actions/sentinelDateActions";

const sentinelHubList = [
    { "name": "Agriculture", "ID": "AGRICULTURE" },
    { "name": "Bathymetric", "ID": "BATHYMETRIC" },
    { "name": "False color (urban)", "ID": "FALSE-COLOR-URBAN" },
    { "name": "False color (vegetation)", "ID": "FALSE-COLOR" },
    { "name": "Geology", "ID": "GEOLOGY" },
    { "name": "Moisture Index", "ID": "MOISTURE-INDEX" },
    { "name": "Natural color (true color)", "ID": "NATURAL-COLOR" },
    { "name": "NDVI", "ID": "NDVI" },
    { "name": "SWIR", "ID": "SWIR" },
    { "name": "TRUE COLOR S2L2A", "ID": "TRUE-COLOR-S2L2A" },
];

function SentinelHubSelectBox() {
    const dispatch = useDispatch();
    const [layer, setLayer] = useState("");
    const [sentinelHubLayerChecked, setSentinelHubLayerChecked] =
        useState(true);

    const sentinelHubToggleCheck = (e) => {
        let status = !sentinelHubLayerChecked;
        setSentinelHubLayerChecked(status);
        dispatch(setActiveLayers({ sentinelHubLayer: status }));
    };

    return (
        <>
            <Grid container direction="column">
                <Grid item >
                    <Grid container alignContent="center" alignItems="center">
                        <Grid item xs={2} >
                            <Button
                                startIcon={
                                    sentinelHubLayerChecked ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )
                                }
                                fullWidth
                                style={{ width: "auto" }}
                                onClick={sentinelHubToggleCheck}
                            ></Button>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl fullWidth>
                                <InputLabel id="sentinel">Sentinel</InputLabel>
                                <Select
                                    labelId="sentinel"
                                    id="sentinel"
                                    onChange={(e) => {
                                        let selectedLayer = e.target.value;
                                        let sentinelLayer =
                                            sentinelHubList.find(
                                                (layer) =>
                                                    layer.ID === selectedLayer
                                            );
                                        dispatch(
                                            setSentinelLayer(sentinelLayer)
                                        );
                                        setLayer(e.target.value);
                                    }}
                                    value={layer}
                                >
                                    {sentinelHubList.map((layer, index) => {
                                        return (
                                            <MenuItem
                                                value={layer.ID}
                                                key={index}
                                            >
                                                {layer.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container direction="column">
                    <Grid item md={12}>
                        <Typography>Timeline</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Grid container>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    id="start-date"
                                    label="Start Date"
                                    type="date"
                                    onChange={(e) => {
                                        dispatch(
                                            setSentinelDate({
                                                startDate: e.target.value,
                                            })
                                        );
                                    }}
                                    style={{ width: "100%" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    id="end-date"
                                    label="End Date"
                                    type="date"
                                    onChange={(e) => {
                                        dispatch(
                                            setSentinelDate({
                                                endDate: e.target.value,
                                            })
                                        );
                                    }}
                                    style={{ width: "100%" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SentinelHubSelectBox;
