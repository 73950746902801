import { AppBar, Button, Tab, Tabs } from "@material-ui/core";
import { CloudDownload, ShowChart, TableChart } from "@material-ui/icons";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import DataChart from "./DataChart";
import DataTable from "./DataTable";

function DataTabNavigation() {
  const [selectedTab, setSelectedTab] = useState("data-chart");

  return (
    <TabContext value={selectedTab}>
      <AppBar
        position="static"
        style={{ justifyContent: "center" }}
        variant="elevation"
      >
        <Tabs
          value={selectedTab}
          onChange={(e, selectedTab) => setSelectedTab(selectedTab)}
          variant="fullWidth"
        >
          <Tab
            // label="Data chart"
            title="Data chart"
            icon={<ShowChart />}
            id="data-chart"
            value="data-chart"
          />
          <Tab
            // label="Data table"
            title="Data table"
            icon={<TableChart />}
            id="data-table"
            value="data-table"
          />
          <Tab
            // label="Dowload Data"
            title="Download data"
            icon={<CloudDownload />}
            id="data-download"
            value="data-download"
          />
        </Tabs>
      </AppBar>
      <TabPanel value="data-chart">
        <DataChart />
      </TabPanel>
      <TabPanel value="data-table">
        <DataTable />
      </TabPanel>
      <TabPanel value="data-download" style={{ textAlign: "center" }}>
        <Button variant="contained" color="primary">
          <CloudDownload style={{ marginRight: 3 }} />
          Download
        </Button>
      </TabPanel>
    </TabContext>
  );
}

export default DataTabNavigation;
