import React, { useRef, useState } from "react";
import { useEffect } from "react";

import ReactMapboxGl, { Layer, Source } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";

import { geojsonToWKT } from "@terraformer/wkt";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useSelector } from "react-redux";
import { getBounds } from "../functions";

import mapboxgl from "mapbox-gl";
import axios from "axios";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MAPBOX_TOKEN =
    "pk.eyJ1IjoiZG9jbGlnb3QiLCJhIjoiY2p3MHQ5MTViMGVvNzQzdGdicTlwM2o3NCJ9.j4qYChJYSxUy8hNnlXrD-g";

const Map = new ReactMapboxGl({
    accessToken: MAPBOX_TOKEN,
});

function MapBox() {
    const sedacURL = `https://sedac.ciesin.columbia.edu/geoserver/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=`;
    const sentinelURL = `https://services.sentinel-hub.com/ogc/wms/28214dc9-9f62-4c72-bbb7-4bebf0dc08be?REQUEST=GetMap&SERVICE=WMS&maxcc=20&width=320&height=320&bbox={bbox-epsg-3857}&srs=EPSG:3857&layers=`;
    const country = useSelector((state) => state.country);
    const sedac = useSelector((state) => state.sedac);
    const sentinelLayer = useSelector((state) => state.sentinelLayer);
    const activeLayer = useSelector((state) => state.activeLayer);
    const sentinelDateRange = useSelector((state) => state.sentinelDateRange);
    const [layers, setLayers] = useState({
        sedacLayers: [],
        sentinelLayers: [],
    });
    const [dateRange, setDateRange] = useState({})
    const [sedacLayerProps, setSedacLayerProps] = useState([]);
    const [sentinelLayerList, setSentinelLayerList] = useState([]);
    const [prevCountrySelected, setPrevCountrySelected] = useState({});
    // eslint-disable-next-line
    const [currentSentinelLayer, setCurrentSentinelLayer] = useState({});
    const [viewport, setViewport] = useState({
        style: "mapbox://styles/mapbox/dark-v10",
        containerStyle: {
            height: "100vh",
            width: "100vw",
        },
        zoom: [1],
        fitBoundsOptions: {
            offset: [0, 0],
            padding: {
                top: 150,
                bottom: 150,
                left: 150,
                right: 150,
            },
        },
    });
    const layerId = useRef(0);
    const map = useRef();

    const setTime = (date) => {
        if (date.startDate && date.endDate)
            return `&TIME=${date.startDate}/${date.endDate}`;

        return "";
    };

    const onDrawChange = async ({ features }) => {
        let date = dateRange

        let polygon = geojsonToWKT(features[0].geometry);
        let res = await axios.get(
            `https://services.sentinel-hub.com/ogc/fis/28214dc9-9f62-4c72-bbb7-4bebf0dc08be`,
            {
                params: {
                    bins: 32,
                    crs: "CRS:84",
                    geometry: polygon,
                    layer: "SWIR",
                    // maxcc: 100,
                    resolution: "1400m",
                    time: `${date.startDate}/${date.endDate}`,
                    type: "EQUALFREQUENCY",
                },
            }
        );
        console.log("Sentinel data", res.data);
    };

    useEffect(() => {
        setDateRange(sentinelDateRange)
        console.log(sentinelLayer);
        setCurrentSentinelLayer(sentinelLayer);

        //Update viewport everytime country is changed
        if (prevCountrySelected.iso_a3 !== country.iso_a3) {
            setPrevCountrySelected(country);
            setViewport({
                ...viewport,
                fitBounds: country.geometry
                    ? getBounds(country.geometry.coordinates)
                    : null,
            });
        }

        if (sentinelLayer.ID) {
            sentinelLayerList.pop();

            sentinelLayerList.push({
                id: `sentinel-layer-${layerId.current}`,
                tileJsonSource: {
                    type: "raster",
                    tiles: [
                        `${sentinelURL}${sentinelLayer.ID}${setTime(
                            sentinelDateRange
                        )}`,
                    ],
                },
            });

            setSentinelLayerList(sentinelLayerList);
            layerId.current = layerId.current + 1;
        }

        if (sedac.length) {
            sedacLayerProps.pop();

            sedacLayerProps.push({
                id: `sedac-layer-${layerId.current}`,
                tileJsonSource: {
                    type: "raster",
                    tiles: [`${sedacURL}${sedac}`],
                    tileSize: 512,
                },
            });
            setSedacLayerProps(sedacLayerProps);
            layerId.current = layerId.current + 1;
        }

        let _layers = {
            sedacLayers: activeLayer.sedacLayer ? sedacLayerProps : [],
            sentinelLayers: activeLayer.sentinelHubLayer
                ? sentinelLayerList
                : [],
        };

        setLayers(_layers);

        return () => {};
        // eslint-disable-next-line
    }, [country, sedac, sentinelLayer, sentinelDateRange, activeLayer]);

    return (
        <Map {...viewport} ref={map}>
            {layers.sedacLayers.map((layerProp, index) => {
                return (
                    <>
                        <Source {...layerProp} key={layerProp.id} />
                        <Layer
                            id={layerProp.id}
                            type="raster"
                            sourceId={layerProp.id}
                            paint={{ "raster-opacity": 0.5 }}
                            beforeId="geojson-lines"
                            key={`${layerProp.id}-index`}
                        />
                    </>
                );
            })}

            {layers.sentinelLayers.map((layerProp, index) => {
                return (
                    <React.Fragment key={index}>
                        <Source {...layerProp} key={layerProp.id} />
                        <Layer
                            id={layerProp.id}
                            type="raster"
                            sourceId={layerProp.id}
                            paint={{ "raster-opacity": 0.5 }}
                            beforeId="geojson-lines"
                            key={`${layerProp.id}-index`}
                        />
                    </React.Fragment>
                );
            })}

            <DrawControl
                position="bottom-right"
                onDrawCreate={onDrawChange}
                onDrawUpdate={onDrawChange}
                onDrawCombine={(e) => console.log(e.createdFeatures)}
                controls={{
                    polygon: true,
                    lines: false,
                    point: false,
                    combine_features: false,
                    uncombine_features: false,
                    line_string: false,
                }}
            />
            <Source
                id="geojson-layer"
                type="geojson"
                geoJsonSource={{ type: "geojson", data: country }}
            />
            <Layer
                id="geojson-lines"
                type="line"
                paint={{
                    "line-color": "#fff",
                    "line-width": 3,
                }}
                sourceId="geojson-layer"
            />
        </Map>
    );
}

export default MapBox;
