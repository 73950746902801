import { Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLegend } from "../actions/legendActions";
import { setSedacLayer } from "../actions/sedacLayerActions";


function TimelineRangeSlider() {
  const category = useSelector((state) => state.category);
  const [_sedacLayer, _setSedacLayer] = useState("");
  const [timelines, setTimelines] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let timelines = category.timelines || [];
    setTimelines(timelines);
    if (timelines.length) {
      _setSedacLayer(timelines[0].key)
      selectYear(timelines[0])
    }
    // eslint-disable-next-line
  }, [category]);


  const selectYear = (sedacLayer) => {
    dispatch(setSedacLayer(sedacLayer.key));
    dispatch(setLegend(sedacLayer.legends));
  };

  return category.timelines ? (
    <div>
      <Typography id="discrete-slider-restrict" gutterBottom>
        Timeline
      </Typography>
      <ToggleButtonGroup
        value={_sedacLayer}
        exclusive
      >
        {timelines.map((timeline, index) => {
          return (
            <ToggleButton
              value={timeline.key}
              onClick={(e) => {
                selectYear(timeline);
                _setSedacLayer(timeline.key);
              }}
              key={index}
            >
              {timeline.year}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  ) : (
    ""
  );
}

export default TimelineRangeSlider;
