import axios from "axios";

export const setCountries = (countries) => {
  return {
    type: "SET_COUNTRIES",
    payload: countries,
  };
};

export const fetchCountries = () => {

  return async (dispatch, getState) => {
    let response = await axios.get(
      `https://drmkc.jrc.ec.europa.eu/Inform-Index/API/InformAPI/countries/Index`
    );
    let countries = await response.data.filter((country) => {
      return country.CategoryType === "ADMIN0";
    });

    dispatch(setCountries(countries));
  };
};
