const countriesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case "FETCH_COUNTRIES":
      return state;
      // eslint-disable-next-line
      break;
    case "SET_COUNTRIES":
      return payload;
      // eslint-disable-next-line
      break;
    case "LOAD_COUNTRIES":
      return state;
      // eslint-disable-next-line
      break;

    default:
      return state;
      // eslint-disable-next-line
      break;
  }
};

export default countriesReducer;
