const sidebarReducer = (state = false, { type, payload }) => {
  switch (type) {
    case "TOGGLE_SIDEBAR":
      return !state;

    default:
      return state;
  }
};

export default sidebarReducer;
