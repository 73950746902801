const categoryReducer = (state = {}, { type, payload }) => {
    switch (type) {

        case 'SET_CATEGORY':
            return payload

        default:
            return state
    }
}


export default categoryReducer