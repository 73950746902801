const initialState = {
    sedacLayer: true,
    sentinelHubLayer: true
}

const layersReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case 'SET_ACTIVE_LAYERS':
            return { ...state, ...payload }

        default:
            return state
    }
}

export default layersReducer
